import {
  EXPERIENCE_REMOVE_REVIEW_TESTING,
  EXPERIENCE_SHOW_PRODUCT_RELIEF_TESTING,
  EXPERIENCE_SHOW_CATEGORY_RELIEF_TESTING,
} from 'actions/types';
import {
  shouldRemoveReviewStep,
  showProductReliefTypeSelections,
  showCategoryReliefTypeSelections,
} from 'lib/core/abTests';
import { Dispatch } from 'redux';

const initABTesting = () => {
  return async (dispatch: Dispatch) => {
    // need this no matter what if user is logged in or not
    // MARK: test fetching from apps/dashboard/src/lib/core/abTests.ts
    // goes below along with dispatches for said tests

    const removeReviewStep = shouldRemoveReviewStep();
    const productReliefTypeTesting = showProductReliefTypeSelections();
    const categoryReliefTypeTesting = showCategoryReliefTypeSelections();

    dispatch({ type: EXPERIENCE_REMOVE_REVIEW_TESTING, payload: removeReviewStep });
    dispatch({
      type: EXPERIENCE_SHOW_PRODUCT_RELIEF_TESTING,
      payload: productReliefTypeTesting,
    });
    dispatch({
      type: EXPERIENCE_SHOW_CATEGORY_RELIEF_TESTING,
      payload: categoryReliefTypeTesting,
    });
  };
};

export { initABTesting };
