import TopBannerWithProgress from 'components/checkout-experience/TopBannerWithProgress';
import Layout from 'containers/Layout';
import React from 'react';

const GenericThankYou: React.FC = () => {
  return (
    <Layout title='Thank you | Alloy' desc='Thank you!' noBars>
      <TopBannerWithProgress />

      <section className='auth-section generic-help'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-12 col-md-7 help-text justify-content-center'>
              <h2>Thanks so much!</h2>
              <p>You're on the list! Stay tuned for your calendar invite and link to join!</p>
            </div>
          </div>
          <div className='row btn-row'>
            <div className='col-12 text-center'>
              <a href={process.env.REACT_APP_MARKETING_URL} className='primary-button'>
                Back to myalloy.com
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default GenericThankYou;
