import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import logo from 'assets/svg/logo.svg';

import { logout } from 'actions/auth/logout_actions';

import { navItems } from 'data/navbar';
import { useAppSelector } from 'reducers/alloy_reducer';

interface Props {
  current?: string;
}

export default function NavBar({ current }: Props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const authenticated = useAppSelector((state) => state.alloy.isAuthenticated);

  const dispatchLogout = bindActionCreators(logout, dispatch);

  const [showMobileNav, setShowMobileNav] = useState(false);

  useEffect(() => {
    document.body.classList.remove('openMenu');
  }, []);

  const toggleMobileNav = () => {
    if (showMobileNav) {
      document.body.classList.remove('openMenu');
      setShowMobileNav(false);
    } else {
      document.body.classList.add('openMenu');
      setShowMobileNav(true);
    }
  };

  const logoutUser = () => {
    dispatchLogout(history);
  };

  return (
    <>
      <div className='navbar-dark nav-main dark'>
        <nav
          id='navbar-main'
          className='navbar navbar-fixed-top navbar-main navbar-expand-xl'
          aria-label='Primary navigation'
        >
          <div className='container-fluid px-lg-6'>
            <div className='d-flex ml-3 ml-lg-0 align-items-center'>
              <Link to='/' className='navbar-brand'>
                <img className='logo' alt='Alloy logo' src={logo} />
              </Link>
            </div>

            <div className={showMobileNav ? 'd-none' : 'nav-menu-btn d-flex d-xl-none'}>
              <a
                href={`${process.env.REACT_APP_MARKETING_URL}/solutions`}
                className='btn nav-menu-btn-link'
              >
                Solutions
              </a>
            </div>

            <button onClick={toggleMobileNav} id='menu' className='d-block d-xl-none'>
              <span className='menu-bars dark' />
            </button>

            <div className='d-xl-flex align-items-center navigation'>
              <ul className='navbar-nav navbar-nav-hover align-items-xl-center'>
                {navItems.map((item, index) =>
                  item.dropdown ? (
                    <li className='nav-item nav-item-dropdown' key={index}>
                      <p className='nav-link d-flex'>
                        <span
                          className={`nav-link-dropdown-title ${
                            !item.fancyLink ? 'full-width' : ''
                          }`}
                        >
                          {item.title}
                        </span>
                        {item.fancyLink && (
                          <span className='d-inline-block d-lg-none nav-item-fancy-link'>
                            {item.fancyLink}
                          </span>
                        )}
                      </p>

                      <ul className='nav-item-dropdown-list'>
                        {item.dropdown.map((dropdown, dIndex) => (
                          <li className='nav-item-dropdown-item' key={dIndex}>
                            <a href={dropdown.link} className='nav-item-dropdown-link'>
                              {dropdown.title}
                            </a>

                            {dropdown.fancyLink && (
                              <p className='nav-item-dropdown-item-link-fancy'>
                                {dropdown.fancyLink}
                              </p>
                            )}
                          </li>
                        ))}

                        {item.fancyLink && (
                          <li className='nav-item-dropdown-item-fancy'>{item.fancyLink}</li>
                        )}
                      </ul>
                    </li>
                  ) : (
                    <li
                      className={`nav-item ${
                        item.title.toLowerCase() === current ? 'selected' : ''
                      }`}
                      key={index}
                    >
                      {item.fancyLink && item.fancyLink}
                      {item.link && (
                        <a href={item.link} className='nav-link'>
                          <span className='nav-link-inner-text'>{item.title}</span>
                        </a>
                      )}
                    </li>
                  )
                )}

                <li className='nav-item'>
                  {authenticated ? (
                    <a onClick={logoutUser} className='nav-link orange'>
                      <span className='nav-link-inner-text' style={{ cursor: 'pointer' }}>
                        Logout
                      </span>
                    </a>
                  ) : (
                    <Link to='/' className='nav-link orange'>
                      <span className='nav-link-inner-text'>Login</span>
                    </Link>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}
