import ProductReviewCardBlock from '../ProductReviewCardBlock';
import ReviewSection from '../ReviewSection';

import { updateCart } from 'actions/checkout-experience/cart_actions';
import { getRecentSubmission, runSuggestions } from 'client/dist/generated/alloy';
import ProductRegistry from 'client/dist/product/productRegistry';
import { convertCentsToDollars } from 'lib/shared/convert';
import { retrieveIntakeCategoriesFromUrl } from 'lib/shared/experience';
import { getPriceForCombinedProducts } from 'lib/shared/product';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useAppSelector } from 'reducers/alloy_reducer';
import { bindActionCreators } from 'redux';

interface Props {
  onBack?: () => void;
  onNext: () => void;
}

export default function IndividualContent({ onBack, onNext }: Props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const cart = useAppSelector((state) => state.experience.alloyCart);
  const localPreCustomer = useAppSelector((state) => state.experience.localPreCustomer);
  const isAuthenticated = useAppSelector((state) => state.alloy.isAuthenticated);

  const dispatchUpdateCart = bindActionCreators(updateCart, dispatch);

  const onContinue = async () => {
    setIsLoading(true);

    let submissionId = localPreCustomer.alloySubmissionId ?? '';

    if (isAuthenticated) {
      const intakeCategories = retrieveIntakeCategoriesFromUrl(location);

      const recentSubmission = await getRecentSubmission({ categories: intakeCategories });

      submissionId = recentSubmission.id!;
    }

    const suggestions = await runSuggestions({ submissionId });

    const qualifiedProductIds = suggestions.qualified.map((q) => q.product.productId);

    const fetchedProducts = (
      await ProductRegistry.get().getRecurringProductsForV2(qualifiedProductIds)
    ).flat();

    dispatchUpdateCart({ products: fetchedProducts });
    setIsLoading(false);
    onNext();
  };

  return (
    <ReviewSection
      isLoading={isLoading}
      onBack={onBack}
      isContinueDisabled={cart.products.length === 0}
      onContinue={onContinue}
    >
      <div className='content-header-wrapper'>
        <h1 className='content-title'>
          {cart.products.length === 1 ? 'Your treatment' : 'Your treatments'}
        </h1>
      </div>

      {cart.products.map((gcp, index) => {
        const priceInCents = getPriceForCombinedProducts(gcp);

        return (
          <ProductReviewCardBlock
            key={index}
            topImg={
              gcp.contentfulProduct.fields.productHeader?.fields.file.url ??
              gcp.contentfulProduct.fields.photo.fields.file.url
            }
            title={gcp.contentfulProduct.fields.title}
            subtitle={`$${convertCentsToDollars(priceInCents)} (3-month supply)`}
            description={gcp.contentfulProduct.fields.detail}
            benefitsList={gcp.contentfulProduct.fields.reviewBenefits ?? []}
            showRx
          />
        );
      })}
    </ReviewSection>
  );
}
