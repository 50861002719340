import { capitalize, isEmpty } from 'lodash';
import { useState } from 'react';
import { useLocation } from 'react-router';
import { useAppSelector } from 'reducers/alloy_reducer';
import { Form } from 'rsuite';

import BottomBar from 'components/checkout-experience/BottomBar';
import TopBannerWithProgress from 'components/checkout-experience/TopBannerWithProgress';
import CheckoutExperienceSection from 'components/core/layout/CheckoutExperienceSection';

import Layout from 'containers/Layout';

import {
  CATEGORY_SELECTIONS,
  PRODUCT_SELECTIONS,
  SELECTIONS,
} from 'data/checkout-experience/content/relief-type';

import { getPercentComplete } from 'lib/checkout-experience/progress';

import posthog from 'posthog-js';

import FeaturesBlock from 'components/checkout-experience/expectations/FeaturesBlock';
import ImprovementBlock from 'components/checkout-experience/expectations/ImprovementBlock';
import { FieldsGroup } from 'components/checkout-experience/relief-type/FieldsGroup';
import { featuresWithLightIcons } from 'data/checkout-experience/content/expectations';
import { ExperienceComponentModel } from 'models/alloy/experience';
import { SelectFieldsGroup } from 'components/core/fields/SelectFieldsGroup';

export default function ReliefType({ onNext, onBack }: ExperienceComponentModel) {
  const location = useLocation();

  const [selectedId, setSelectedId] = useState<string | undefined>(undefined);

  const { showProductReliefTypeTesting, showCategoryReliefTypeTesting } = useAppSelector(
    (state) => state.abTesting
  );

  let activeSelections = SELECTIONS;

  if (showCategoryReliefTypeTesting) {
    activeSelections = CATEGORY_SELECTIONS;
  } else if (showProductReliefTypeTesting) {
    activeSelections = PRODUCT_SELECTIONS;
  }

  const customer = useAppSelector((state) => state.alloy.customer);
  const localPreCustomer = useAppSelector((state) => state.experience.localPreCustomer);
  const showEnhancers = showProductReliefTypeTesting || showCategoryReliefTypeTesting;
  const firstName = capitalize(customer ? customer.firstName : localPreCustomer.firstName) ?? '';

  const onSubmit = async () => {
    if (selectedId) {
      const foundSelection = activeSelections.find((s) => s.id === selectedId);

      if (foundSelection) {
        posthog.capture('reliefTypesSelected', {
          $set: {
            intake_categories: foundSelection.categories,
          },
        });

        onNext(foundSelection.categories);
      }
    }
  };

  const selections = activeSelections.map((s) => ({
    text: s.text,
    id: s.id,
    title: s.title,
    categories: s.categories,
    imageConfig: s.imageConfig,
  }));

  return (
    <Layout title='Select relief - Alloy' desc='' noBars>
      <TopBannerWithProgress onBack={onBack} percentComplete={getPercentComplete(location)} />

      <Form>
        <CheckoutExperienceSection
          colClass={
            showProductReliefTypeTesting || showCategoryReliefTypeTesting
              ? 'col-12 col-md-10'
              : undefined
          }
        >
          <h1 className='content-title'>
            {isEmpty(firstName)
              ? 'What brings you to Alloy today?'
              : `Hi ${firstName}, what brings you to Alloy today?`}
          </h1>
          <p className='content-text'>Select the answer that best describes your concerns today.</p>

          {/* TODO: this will be updated later for a more universal/reusable approach (SelectFieldsGroup updated => adjusted basically) */}
          {showProductReliefTypeTesting || showCategoryReliefTypeTesting ? (
            <FieldsGroup selectedId={selectedId} onSelect={setSelectedId} options={selections} />
          ) : (
            <SelectFieldsGroup
              selectedIds={!!selectedId ? [selectedId] : []}
              onChange={(ids) => {
                const id = ids[ids.length - 1];

                setSelectedId(id);
              }}
              options={selections}
              isSingleSelect
            />
          )}

          {showEnhancers && (
            <div className='enhancers-wrapper'>
              <FeaturesBlock data={featuresWithLightIcons} customBg='dark-bg' />
              <ImprovementBlock
                title='95% of women '
                text='will see improvement in symptoms within 4-12 weeks of starting treatment'
              />
            </div>
          )}
        </CheckoutExperienceSection>

        <BottomBar>
          <button
            className='primary-button'
            type='submit'
            disabled={!selectedId}
            onClick={onSubmit}
          >
            Next
          </button>
        </BottomBar>
      </Form>
    </Layout>
  );
}
