import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

interface SeoModel {
  title: string;
  description?: string;
}

export default function SEO({ title, description }: SeoModel) {
  const location = useLocation();
  const osanoToken = process.env.REACT_APP_OSANO_TOKEN;

  return (
    <Helmet>
      <title>{title}</title>

      {/* <!-- TrustBox script --> */}
      <script
        type='text/javascript'
        src='//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
        async
      ></script>
      {/* <!-- End TrustBox script -->    */}

      <meta property='og:title' content={title} />
      <meta name='twitter:title' content={title} />

      <meta name='description' content={description} />
      <meta name='twitter:description' content={description} />
      <meta name='og:description' content={description} />

      <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
      <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
      <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
      <link rel='manifest' href='/site.webmanifest' />
      <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#09063b' />
      <meta name='msapplication-TileColor' content='#09063b' />
      <meta name='theme-color' content='#ffffff' />

      <link rel='canonical' href={`${process.env.REACT_APP_DASHBOARD_URL}${location.pathname}`} />

      {/* Osano cookie consent / privacy script. Must be first in block of scripts because, depending on user's choices, it may need to selectively block subsequent scripts */}
      {osanoToken && (
        <script
          src={`https://cmp.osano.com/16BaaWUMr0MdV3HYQ/${osanoToken}/osano.js`}
          async
        ></script>
      )}

      <script type={'text/javascript'}>
        {`
          <!-- Start VWO Async SmartCode -->
window._vwo_code = window._vwo_code || (function(){
var account_id=630447,
settings_tolerance=2000,
library_tolerance=2500,
use_existing_jquery=false,
is_spa=1,
hide_element='body',

/* DO NOT EDIT BELOW THIS LINE */
f=false,d=document,code={use_existing_jquery:function(){return use_existing_jquery;},library_tolerance:function(){return library_tolerance;},finish:function(){if(!f){f=true;var a=d.getElementById('_vis_opt_path_hides');if(a)a.parentNode.removeChild(a);}},finished:function(){return f;},load:function(a){var b=d.createElement('script');b.src=a;b.type='text/javascript';b.innerText;b.onerror=function(){_vwo_code.finish();};d.getElementsByTagName('head')[0].appendChild(b);},init:function(){
window.settings_timer=setTimeout(function () {_vwo_code.finish() },settings_tolerance);var a=d.createElement('style'),b=hide_element?hide_element+'{opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}':'',h=d.getElementsByTagName('head')[0];a.setAttribute('id','_vis_opt_path_hides');a.setAttribute('type','text/css');if(a.styleSheet)a.styleSheet.cssText=b;else a.appendChild(d.createTextNode(b));h.appendChild(a);this.load('https://dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+(+is_spa)+'&r='+Math.random());return settings_timer; }};window._vwo_settings_timer = code.init(); return code; }());
<!-- End VWO Async SmartCode -->
            `}
      </script>
      <script type='text/javascript'>
        {`(function(c,a){if(!a.__SV){var b=window;try{var d,m,j,k=b.location,f=k.hash;d=function(a,b){return(m=a.match(RegExp(b+"=([^&]*)")))?m[1]:null};f&&d(f,"fpState")&&(j=JSON.parse(decodeURIComponent(d(f,"fpState"))),"fpeditor"===j.action&&(b.sessionStorage.setItem("_fpcehash",f),history.replaceState(j.desiredHash||"",c.title,k.pathname+k.search)))}catch(n){}var l,h;window.freshpaint=a;a._i=[];a.init=function(b,d,g){function c(b,i){var a=i.split(".");2==a.length&&(b=b[a[0]],i=a[1]);b[i]=function(){b.push([i].concat(Array.prototype.slice.call(arguments,
            0)))}}var e=a;"undefined"!==typeof g?e=a[g]=[]:g="freshpaint";e.people=e.people||[];e.toString=function(b){var a="freshpaint";"freshpaint"!==g&&(a+="."+g);b||(a+=" (stub)");return a};e.people.toString=function(){return e.toString(1)+".people (stub)"};l="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove people group page alias ready addEventProperties addInitialEventProperties removeEventProperty addPageviewProperties".split(" ");
            for(h=0;h<l.length;h++)c(e,l[h]);var f="set set_once union unset remove delete".split(" ");e.get_group=function(){function a(c){b[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));e.push([d,call2])}}for(var b={},d=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<f.length;c++)a(f[c]);return b};a._i.push([b,d,g])};a.__SV=1.4;b=c.createElement("script");b.type="text/javascript";b.async=!0;b.src="undefined"!==typeof FRESHPAINT_CUSTOM_LIB_URL?
            FRESHPAINT_CUSTOM_LIB_URL:"//perfalytics.com/static/js/freshpaint.js";(d=c.getElementsByTagName("script")[0])?d.parentNode.insertBefore(b,d):c.head.appendChild(b)}})(document,window.freshpaint||[])

            freshpaint.init("${process.env.REACT_APP_FRESHPAINT_AUTOTRACK}", {secure_cookie: true});
            window.freshpaint?.addEventProperties({ limited_data_use: true });
            freshpaint.page();
        `}
      </script>

      <script type='text/javascript'>
        {`function nantu_show_page() {
          document.body.classList.remove("nantu_antiflicker");
        }

          if(typeof(window.nantu_unhide) === "undefined") {
          window.nantu_unhide = false;
        }

          if(window.nantu_unhide) {
          nantu_show_page();
        } else {
          setTimeout(nantu_show_page, 3000);
        }
        `}
      </script>
    </Helmet>
  );
}
