import classNames from 'classnames';
import checkedRadioIcon from 'assets/svg/core/icons/checked-radio-icon.svg';
import uncheckedRadioIcon from 'assets/svg/core/icons/unchecked-radio-icon.svg';
import { ReliefTypeSelection } from 'data/checkout-experience/content/relief-type';
import { SelectionImage } from './SelectionImage';

interface Props {
  option: ReliefTypeSelection;
  selectedId?: string;
  onSelect: () => void;
}

/**
 * This is a styled radio select field with a title,
 * description, and optional image
 */

export default function SelectImageField({
  option: { text, title, id, imageConfig },
  selectedId,
  onSelect,
}: Props) {
  const isSelected = selectedId === id;

  return (
    <div className='field-checkbox select-image-field'>
      <div
        className={classNames('select-image-inner-wrapper', isSelected && 'selected')}
        onClick={onSelect}
      >
        <div className='inner-content-wrapper'>
          <img
            src={isSelected ? checkedRadioIcon : uncheckedRadioIcon}
            alt='selectable radio icon'
            className='checkbox-select-icon'
          />

          <div className='content-block'>
            <div className='content-title'>{title}</div>
            <div className='content-text'>{text}</div>
          </div>
        </div>

        {imageConfig && <SelectionImage {...imageConfig} />}
      </div>
    </div>
  );
}
