import {
  useGenerateSetupIntent,
  useGetPaymentMethods,
  useGetShippingMethods,
  useGetTreatmentPlan,
  viewedPendingTreatmentPlan,
} from 'client/dist/generated/alloy';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { xor } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateTPCart } from 'actions/treatment-plan/cart_actions';

import Loader from 'components/core/Loader';
import SectionWrapper from 'components/core/layout/SectionWrapper';
import TestimonialsSection from '../../shared/sections/Testimonials';
import DoctorBlock from '../core/DoctorBlock';
import HeaderBlock from '../core/HeaderBlock';
import ListSelectableProducts from '../core/ListSelectableProducts';
import OrderSummaryBlock from '../submit/OrderSummaryBlock';

import DashboardContainer from 'containers/dashboard/DashboardContainer';

import {
  filterMhtProductsFrom,
  filterNonMhtProductsFrom,
  sortMhtProducts,
} from 'lib/dashboard/treatmentPlan';
import { getProductsWithRecurrence, translateToDomProduct } from 'lib/shared/product';

import { SINGLE_SUPPLY_ID } from 'common/dist/products/productRegistry';
import OrderSummaryProductPerksWrapper from 'components/shared/checkout/layout/OrderSummaryProductPerksWrapper';
import { transformDateString } from 'lib/shared/date';
import { useAppSelector } from 'reducers/alloy_reducer';

interface Props {
  onContinueCheckout: () => void;
}

export default function PendingApproval({ onContinueCheckout }: Props) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(true);

  // Grouped products used to display content
  const [groupedProducts, setGroupedProducts] = useState<GroupedContentfulProduct[][]>([]);

  const customer = useAppSelector((state) => state.alloy.customer!!);
  const cart = useAppSelector((state) => state.treatmentPlan.treatmentPlanCart);

  // Data fetching
  const { data: treatmentPlan } = useGetTreatmentPlan();
  const { data: paymentMethods = [], isLoading: isLoadingPaymentMethods } = useGetPaymentMethods();
  const { data: setupIntent, isLoading: isLoadingSetupIntent } = useGenerateSetupIntent();
  const { isLoading: isLoadingShippingMethods } = useGetShippingMethods(customer.stateAbbr!!);

  const isLoading =
    loading || isLoadingPaymentMethods || isLoadingSetupIntent || isLoadingShippingMethods;

  // Selected products the customer would like to purchase, will be here and in our treatment plan cart
  const [selectedProducts, setSelectedProducts] = useState<GroupedContentfulProduct[]>([]);

  const dispatchUpdateCart = bindActionCreators(updateTPCart, dispatch);

  useEffect(() => {
    auditPending();
  }, []);

  useEffect(() => {
    fetchedProducts();
  }, [JSON.stringify(treatmentPlan)]);

  const fetchedProducts = async () => {
    setLoading(true);

    const pendingChargesProducts = treatmentPlan
      ? treatmentPlan.pendingCharges.flatMap((pc) => pc.products)
      : [];

    const products = await getProductsWithRecurrence(pendingChargesProducts);
    const mhtProducts = sortMhtProducts(filterMhtProductsFrom(products.flat()));
    const nonMhtProducts = filterNonMhtProductsFrom(products);

    let selected = nonMhtProducts.flat();

    if (mhtProducts.length !== 0) {
      selected.push(mhtProducts[0]);
    }

    const updatedProducts = !!cart.products.length ? cart.products : selected;

    dispatchUpdateCart({
      products: updatedProducts,
    });

    setGroupedProducts(products);
    setSelectedProducts(updatedProducts);
    setLoading(false);
  };

  const auditPending = async () => {
    await viewedPendingTreatmentPlan({
      pendingChargeIds: treatmentPlan!.pendingCharges.map((pc) => pc.id),
    });
  };

  /**
   * Selecting a product and attaching to our cart or removing!
   */
  const onSelectProduct = async (
    product: GroupedContentfulProduct,
    prevSelected: GroupedContentfulProduct[] = selectedProducts
  ) => {
    const selectedContentfulIds = xor(
      prevSelected.map((gcp) => gcp.contentfulProduct.sys.id),
      [product].map((gcp) => gcp.contentfulProduct.sys.id)
    );

    const filteredProducts = groupedProducts.flatMap((gcpList) =>
      gcpList.filter((gcp) => selectedContentfulIds.includes(gcp.contentfulProduct.sys.id))
    );

    // TODO: mikhail dropping a note, after thinking more on the structure around this, the cart is the
    // selected products, when time comes to clean carts and have more universal, we'll be adjusting a lot
    // of that state structure to handle the what is selected (cart) vs what is for display, etc
    // main reason is that cart holds the true products (eg m4, tret, pill should be m4, tret discount price,
    // pill in the cart)
    const products = (
      await getProductsWithRecurrence(
        filteredProducts.flatMap((gcp) => [
          ...gcp.alloyProduct.parent.map((p) => translateToDomProduct(p)),
          ...(gcp.alloyProduct.child?.map((p) => translateToDomProduct(p)) || []),
        ])
      )
    ).flat();

    dispatchUpdateCart({
      products,
    });

    setSelectedProducts(products);
  };

  const onSwitch = async (
    prevSelected: GroupedContentfulProduct,
    product: GroupedContentfulProduct
  ) => {
    const switchSelected = selectedProducts
      .flat()
      .filter((gcp) => gcp.contentfulProduct.sys.id !== prevSelected.contentfulProduct.sys.id);

    onSelectProduct(product, switchSelected);
  };

  if (isLoading || !setupIntent) {
    return (
      <DashboardContainer title='Treatment Plan | Alloy' desc='' currentPage='treatment plan'>
        <Loader />
      </DashboardContainer>
    );
  }

  const selectedProductFrequencies = selectedProducts
    .map((sp) => [...sp.alloyProduct.parent, ...(sp.alloyProduct.child ?? [])])
    .flat()
    .filter((p) => p.type !== 'OTC'); // filter out otc since if the mht product(s) are a single supply, it should take precedent over it

  const onlySingleSupplySelected =
    !!selectedProductFrequencies.length &&
    selectedProductFrequencies.every((pf) => pf.frequencyId === SINGLE_SUPPLY_ID);

  return (
    <>
      <DashboardContainer title='Treatment Plan | Alloy' desc='' currentPage='treatment plan'>
        <HeaderBlock title='Treatment plan for' fancyTitle={customer.firstName ?? ''} />

        <SectionWrapper sectionClasses='dashboard-treatment-plan-section tp-pending-approval-section'>
          <div className='col-12 col-lg-7 tp-pending-approval-selection'>
            <DoctorBlock />

            {treatmentPlan?.messagingWidgetExpiry && (
              <p className='tp-messaging-expiration-banner'>
                {`Approve your treatment plan to feel great and get ${
                  onlySingleSupplySelected ? 'continued' : 'unlimited'
                } doctor messaging. Otherwise, your doctor access ends ${transformDateString(
                  treatmentPlan?.messagingWidgetExpiry,
                  'MM/DD/YYYY'
                )}.`}
              </p>
            )}

            <div className='tp-selected-treatments-section'>
              <p className='selected-text'>{`${selectedProducts.length} ${
                selectedProducts.length > 1 ? `treatments` : `treatment`
              } selected`}</p>

              <p className='selected-info'>
                Please check the treatments you would like to receive. Any treatments you deselect
                will remain in your treatment plan and can be purchased at a later time.
              </p>
            </div>

            <ListSelectableProducts
              products={groupedProducts}
              selectedProducts={selectedProducts}
              onSelect={onSelectProduct}
              onSwitch={onSwitch}
            />
          </div>

          <div className='col-12 col-lg-5 tp-order-summary-col'>
            <OrderSummaryBlock
              paymentMethods={paymentMethods}
              onContinueCheckout={onContinueCheckout}
              onlySingleSupplySelected={onlySingleSupplySelected}
            />

            <OrderSummaryProductPerksWrapper />
          </div>
        </SectionWrapper>

        <TestimonialsSection />
      </DashboardContainer>
    </>
  );
}
