import { Skeleton } from '@mui/material';
import {
  applyPromoCode,
  billSubscriptionNow,
  setNextRecurrence,
  SubscriptionWithRenewal,
  unbundleProductFromSubscription,
  useGetAllSubscriptionsForCustomer,
  useIsProcessing,
} from 'client/dist/generated/alloy';
import DomProductRegistry from 'client/dist/product/productRegistry';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import AlloyDrawer from 'components/core/drawers/AlloyDrawer';
import { showSuccessNotification } from 'components/core/Notification';
import { getUnixTime, isToday, startOfDay } from 'date-fns';
import { getMillisWithRandomTime } from 'lib/shared/date';
import {
  getProductFrequencyIdsFrom,
  getProductIdsFromGroupedProducts,
  getProductToBeBundledWith,
} from 'lib/shared/product';
import { capitalize, first } from 'lodash';
import { ManageLevel, ManageType } from 'models/components/shared/manage-type';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import EditProductWrapper from './wrappers/EditProductWrapper';
import RescheduleWrapper from './wrappers/RescheduleWrapper';
import ShipNowWrapper from './wrappers/ShipNowWrapper';
import { useSubscriptionContext } from 'context/dashboard/subscriptions/manage';
import { getFilteredPFIdsFrom } from 'lib/shared/subscriptions/product-filter';

interface Props {
  selectedProductIds: number[];
  selectedType: ManageType;
  manageLevel: ManageLevel;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  onPause: () => void;
}

export default function ManageDrawer({
  selectedProductIds,
  selectedType,
  manageLevel,
  open,
  setOpen,
  onPause,
}: Props) {
  const history = useHistory();

  const { subscription } = useSubscriptionContext();

  const [isLoading, setIsLoading] = useState(true);
  const [manageType, setManageType] = useState<ManageType>(selectedType);

  const [managingProducts, setManagingProducts] = useState<GroupedContentfulProduct[][]>([]);
  const [parentProduct, setParentProduct] = useState<GroupedContentfulProduct | undefined>();

  const { mutate: mutateSubscriptions } = useGetAllSubscriptionsForCustomer();
  const { mutate: mutateIsProcessing } = useIsProcessing(subscription.stripeSubscriptionId);

  const mutate = async () => await Promise.all([mutateSubscriptions(), mutateIsProcessing()]);

  useEffect(() => {
    const getProducts = async () => {
      setIsLoading(true);

      const [products, subProducts] = await Promise.all([
        DomProductRegistry.get().getRecurringProductsForV2(selectedProductIds),
        DomProductRegistry.get().getRecurringProductsForV2(
          subscription.products.map((pfr) => pfr.product.productId)
        ),
      ]);

      const parent = getProductToBeBundledWith(first(products.flat())!, subProducts);

      setManagingProducts(products);
      setManageType(selectedType);

      setParentProduct(parent);

      setIsLoading(false);
    };

    getProducts();
  }, [JSON.stringify(selectedProductIds), selectedType]);

  const onClose = () => {
    // reset the manage type since it displays the manage drawer a little differently
    // on initial open
    if (manageLevel === 'PRODUCT') {
      setManageType('EDIT');
    }

    setOpen(false);
  };

  const onSelectType = (s: ManageType) => (s === 'PAUSE' ? onPause() : setManageType(s));

  const onConfirmReschedule = async (selectedDate: Date, shippingMethodId?: number) => {
    try {
      setIsLoading(true);

      if (isToday(selectedDate)) {
        onConfirmShipNow(shippingMethodId);
      } else if (manageLevel === 'PRODUCT') {
        const startTimestamp = getUnixTime(selectedDate);

        // filter the pf ids off the sub that will be moved
        const filteredProductFrequencyIds = getFilteredPFIdsFrom(
          subscription,
          getProductIdsFromGroupedProducts(managingProducts.flat())
        );

        await unbundleProductFromSubscription({
          stripeSubscriptionId: subscription.stripeSubscriptionId,
          productFrequencyIds: filteredProductFrequencyIds,
          type: 'RESCHEDULE',
          startTimestamp,
          shippingMethodId,
        });
      } else {
        // Getting a random time allows us to mix up the future shipments so that they don't all get
        // hit at the same exact date in the future potentially creating problems for curexa
        const millis = getMillisWithRandomTime(startOfDay(selectedDate));

        await setNextRecurrence(subscription.stripeSubscriptionId, millis, {
          shippingMethodId,
        });
      }

      await mutate();

      if (manageLevel === 'PRODUCT') {
        showSuccessNotification('Your product has successfully been rescheduled');
        history.replace('/subscriptions');
      } else {
        showSuccessNotification('Your shipment has successfully been rescheduled');
        setOpen(false);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onConfirmShipNow = async (shippingMethodId?: number, promotionCodeId?: string) => {
    try {
      setIsLoading(true);

      if (manageLevel === 'PRODUCT') {
        const filteredProductFrequencyIds = getFilteredPFIdsFrom(
          subscription,
          getProductIdsFromGroupedProducts(managingProducts.flat())
        );

        await unbundleProductFromSubscription({
          stripeSubscriptionId: subscription.stripeSubscriptionId,
          productFrequencyIds: filteredProductFrequencyIds,
          type: 'SHIP_NOW',
          promotionCodeId,
          shippingMethodId,
        });
      } else {
        await billSubscriptionNow(subscription.stripeSubscriptionId, {
          shippingMethodId,
        });
      }

      await mutate();

      showSuccessNotification('Your next order has successfully been placed');

      history.replace('/subscriptions');

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <AlloyDrawer
      title={capitalize(manageType.replaceAll('_', ' '))}
      drawerClass='manage-drawer'
      open={open}
      setOpen={onClose}
      disableClosing={isLoading}
    >
      {isLoading ? (
        <div className='drawer-body'>
          <Skeleton variant='rectangular' height={200} />
        </div>
      ) : (
        <div className='drawer-body'>
          {manageType === 'EDIT' && (
            <EditProductWrapper
              products={managingProducts}
              onSelect={onSelectType}
              parentProduct={parentProduct}
            />
          )}

          {manageType === 'RESCHEDULE' && (
            <RescheduleWrapper
              products={managingProducts}
              onPause={onPause}
              onConfirm={onConfirmReschedule}
              parentProduct={parentProduct}
              manageLevel={manageLevel}
            />
          )}

          {manageType === 'SHIP_NOW' && (
            <ShipNowWrapper
              products={managingProducts}
              manageLevel={manageLevel}
              onConfirm={onConfirmShipNow}
              parentProduct={parentProduct}
            />
          )}
        </div>
      )}
    </AlloyDrawer>
  );
}
