import axios from 'axios';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'rsuite';

import { updateCart } from 'actions/checkout-experience/cart_actions';

import CheckboxField from 'components/core/fields/CheckboxField';

import informedConsent from 'data/basics/informedConsent.md';

import classNames from 'classnames';
import { CartAgreement } from 'models/alloy/cart';
import { useAppSelector } from 'reducers/alloy_reducer';

interface Props {
  onPlaceOrder: () => void;
  shouldDisableContinue: boolean;
  isError: boolean;
  setIsError: (error: boolean) => void;
}

export default function TermsWrapper({
  onPlaceOrder,
  shouldDisableContinue,
  isError,
  setIsError,
}: Props) {
  const dispatch = useDispatch();

  const [isConsentOpen, setIsConsentOpen] = useState(false);
  const [consentMarkdown, setConsentMarkdown] = useState('');

  const cart = useAppSelector((state) => state.experience.alloyCart);
  const isEditingShipping = useAppSelector((state) => state.experience.isEditingShipping);

  const dispatchUpdateCart = bindActionCreators(updateCart, dispatch);

  useEffect(() => {
    const getConsentMarkdown = async () => {
      const consent = await axios.get(informedConsent);
      setConsentMarkdown(consent.data);
    };

    getConsentMarkdown();
  }, []);

  const onSelect = (agreement: Partial<CartAgreement>) => {
    dispatchUpdateCart({
      agreement: {
        ...cart.agreement,
        ...agreement,
      },
    });

    if (agreement.isAgreedTerms) {
      setIsError(false);
    }
  };

  return (
    <div className='ce-terms-wrapper'>
      <div className='ce-block-header'>
        <p className='header-title'>
          <span className='header-number'>4</span>
          Terms & conditions{' '}
          <span className={classNames('required-field', isError && 'required-field-error')}>
            * Required
          </span>
        </p>
      </div>

      <div className={classNames('ce-terms-block', isError && 'ce-terms-error')}>
        <CheckboxField
          isPrevSelected={cart.agreement.isAgreedTerms}
          content={
            <>
              By checking this box, I hereby accept the terms of the{' '}
              <button
                className='primary-link-button'
                onClick={(e) => {
                  e.stopPropagation();
                  setIsConsentOpen(true);
                }}
              >
                Consent
              </button>
              ,{' '}
              <a
                href={`${process.env.REACT_APP_MARKETING_URL}/terms-of-use`}
                target='_blank'
                rel='noreferrer'
                className='primary-link-button'
                onClick={(e) => e.stopPropagation()}
              >
                Terms of Service
              </a>
              ,{' '}
              <a
                href={`${process.env.REACT_APP_MARKETING_URL}/privacy-policy`}
                target='_blank'
                rel='noreferrer'
                className='primary-link-button'
                onClick={(e) => e.stopPropagation()}
              >
                Privacy Policy
              </a>
              , &amp;{' '}
              <a
                href={`${process.env.REACT_APP_MARKETING_URL}/telemedicine-consent`}
                target='_blank'
                rel='noreferrer'
                className='primary-link-button'
                onClick={(e) => e.stopPropagation()}
              >
                Telemedicine Consent
              </a>
              .
            </>
          }
          onChange={(isSelected) => onSelect({ isAgreedTerms: isSelected })}
          isOnErrorState={isError}
        />

        {isError && (
          <div className='ce-error-wrapper'>
            <p className='error-text'>Please review your information.</p>
            <ul className='error-list'>
              <li className='list-text'>Terms & Conditions are required to continue.</li>
            </ul>
          </div>
        )}
      </div>

      <div className='ce-block-header notification'>
        <p className='header-title'>SMS Notification Preferences</p>
      </div>

      <div className='ce-sms-block'>
        <div className='ce-checkbox'>
          <CheckboxField
            isPrevSelected={cart.agreement.isOptedSmsMarketing}
            content={'Get personalized marketing texts & exclusive offers.'}
            onChange={(isSelected) => onSelect({ isOptedSmsMarketing: isSelected })}
          />
        </div>

        <p className='ce-terms-text'>
          By signing up for texts, you agree to receive recurring promotional text messages (e.g
          cart reminders, promotional offers etc..) at this number{' '}
          <span>from Alloy Women's Health</span> and these texts may be sent using an autodialer.
          Consent is not a condition of any purchase. Reply HELP for help and STOP to cancel at any
          time. Msg. frequency varies.
        </p>
      </div>

      <div className='d-none d-sm-block'>
        <button
          className='primary-button full-width-button'
          onClick={onPlaceOrder}
          disabled={shouldDisableContinue}
        >
          Place order
        </button>
      </div>

      {/* Sticky CTA for any mobile device */}
      {!isEditingShipping && (
        <div className='ce-sticky-button-block'>
          <button
            className='primary-button full-width-button'
            onClick={onPlaceOrder}
            disabled={shouldDisableContinue}
          >
            Place order
          </button>
        </div>
      )}

      <Modal
        open={isConsentOpen}
        overflow={true}
        size='md'
        className='consent-modal'
        onClose={() => setIsConsentOpen(false)}
      >
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <ReactMarkdown>{consentMarkdown}</ReactMarkdown>
        </Modal.Body>
      </Modal>
    </div>
  );
}
