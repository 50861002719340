export const IS_APP_LOADED = 'is_app_loaded';

/**
 * Alloy
 */
export const IS_AUTHENTICATED = 'is_authenticated';
export const CUSTOMER = 'customers';
export const TOP_ALERT_BANNER = 'top_alert_banner';
export const MESSAGES_BANNER = 'messages_banner';
export const MDI_WIDGET = 'mdi_widget';

/**
 * Checkout experience
 */
export const REQUESTING_PRODUCTS = 'requesting_products';

export const LICENSE_EXISTS = 'license_exists';

export const SUGGESTIONS = 'suggestions';

export const ALLOY_CART = 'alloy_cart';

export const COGNITO_USER = 'cognito_user';
export const LOCAL_USER = 'local_user';

export const CHECKOUT_EDITING_SHIPPING = 'checkout_editing_shipping';
export const BILLING_ADDRESS = 'billing_address';

export const CHECKOUT = 'checkout';

/**
 * Treatment plan
 */
export const TREATMENT_PLAN_CART = 'treatment_plan';

export const SYMPTOMS = 'symptoms';
export const PRESCRIBING_DOCTOR = 'prescribing_doctor';

export const LOADING_TOTALS = 'loading_totals';

// MARK: for any ab tests, iniit the variable with EXPERIENCE_<TEST_NAME>_TESTING
// and just change the <TEST_NAME> bit

/**
 * A/B tests
 */

export const EXPERIENCE_REMOVE_REVIEW_TESTING = 'experience_remove_review_testing';
export const EXPERIENCE_SHOW_PRODUCT_RELIEF_TESTING = 'experience_product_relief_testing';
export const EXPERIENCE_SHOW_CATEGORY_RELIEF_TESTING = 'experience_category_relief_testing';
