import { StripePaymentMethod } from 'client/dist/generated/alloy';

import { getGroupedCartTotals } from 'lib/shared/cart';
import { getProductNamesWithPrice } from 'lib/shared/product';

import { useAppSelector } from 'reducers/alloy_reducer';

interface Props {
  paymentMethods: StripePaymentMethod[];
  onContinueCheckout: () => void;
  onlySingleSupplySelected: boolean;
}

export default function OrderSummaryBlock({
  paymentMethods,
  onContinueCheckout,
  onlySingleSupplySelected,
}: Props) {
  const cart = useAppSelector((state) => state.treatmentPlan.treatmentPlanCart);
  const isLoadingTotals = useAppSelector((state) => state.treatmentPlan.isLoadingTotals);

  const productsCleanNameAndPrice = getProductNamesWithPrice(cart.products);

  const { subtotal } = getGroupedCartTotals(cart);

  return (
    <div className='order-summary-block'>
      <p className='summary-title'>Order Summary</p>

      <div className='summary-products'>
        {productsCleanNameAndPrice.map((pcp, i) => (
          <div key={`tp-product-${i}`} className='tp-products-wrapper'>
            <p className='name-text'>{pcp.cleanName}</p>
            <p className='price-text'>{`$${(pcp.priceInCents / 100).toFixed(2)}`}</p>
          </div>
        ))}
        <div className='tp-products-wrapper'>
          <p className='name-text'>{`${
            onlySingleSupplySelected ? 'Continued' : 'Unlimited'
          } doctor messaging`}</p>
          <p className='price-text'>FREE</p>
        </div>
      </div>

      <p className='messaging-info-banner'>
        {`Every prescription treatment plan purchase unlocks ${
          onlySingleSupplySelected ? 'continued' : 'unlimited'
        } access to a menopause-trained
        physician.`}
      </p>

      <div className='summary-text bottom-padding'>
        <div className='tp-total-wrapper'>
          <p className='total-title'>Order Subtotal</p>
          <p className='total-amount'>
            {isLoadingTotals || subtotal === 0 ? '-' : `$${subtotal.toFixed(2)}`}
          </p>
        </div>

        <button
          className='primary-button full-width-button'
          onClick={onContinueCheckout}
          disabled={cart.products.length === 0 || paymentMethods.length === 0 || isLoadingTotals}
        >
          Continue to checkout
        </button>
      </div>
    </div>
  );
}
