import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import alertIcon from 'assets/svg/core/icons/alert-info-icon.svg';

import ProductListBlock from '../content/ProductList';

import { ManageType } from 'models/components/shared/manage-type';

interface Props {
  products: GroupedContentfulProduct[][];
  onSelect: (selected: ManageType) => void;
  parentProduct: GroupedContentfulProduct | undefined;
}

export default function EditProductWrapper({ products, onSelect, parentProduct }: Props) {
  return (
    <div className='edit-product-wrapper'>
      <ProductListBlock products={products} />

      {!!parentProduct && (
        <div className='product-discount-wrapper'>
          <p className='discount-title'>
            <img src={alertIcon} alt='alert info icon' className='title-icon' />
            Don't lose your discount
          </p>

          <p className='discount-text'>
            This treatment is eligible for a discount when shipped together with{' '}
            {parentProduct.alloyProduct.parent[0].cleanName}. If shipped separately, you'll be
            charged the full price.
          </p>
        </div>
      )}

      <div className='product-btns-wrapper'>
        <button className='secondary-border-button' onClick={() => onSelect('RESCHEDULE')}>
          Reschedule
        </button>

        <button className='primary-button' onClick={() => onSelect('SHIP_NOW')}>
          Ship now
        </button>
      </div>

      <button className='product-link' onClick={() => onSelect('PAUSE')}>
        Pause this treatment
      </button>
    </div>
  );
}
