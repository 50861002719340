import { Product, ProductAndFrequency, SubscriptionWithRenewal } from 'client/dist/generated/alloy';

/**
 * allows to pass in a sub and product ids and get the correct pf ids from that sub
 * filtering against the product ids, this is more so useful for pre product norm
 * but may be useful after unsure atm
 *
 * @param subscription SubscriptionWithRenewal
 * @param productIds Product['id'][] or number[]
 * @returns ProductAndFrequency['id'][] or number[]
 */
export const getFilteredPFIdsFrom = (
  subscription: SubscriptionWithRenewal,
  productIds: Product['id'][]
): ProductAndFrequency['id'][] =>
  subscription.products
    .filter((pfr) => productIds.includes(pfr.product.productId))
    .map((pfr) => pfr.product.id);
