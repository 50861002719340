import { Question as AlloyQuestion } from 'common/dist/models/questionnaire';

import doctors from 'assets/images/checkout-experience/expectations/doctors.png';

import { DateField } from 'components/core/fields/DateField';
import { NumberField } from 'components/core/fields/NumberField';
import { SelectField } from 'components/core/fields/SelectField';
import { FollowUps, SelectFieldsGroup } from 'components/core/fields/SelectFieldsGroup';
import { TextAreaField } from 'components/core/fields/TextAreaField';
import { TextField } from 'components/core/fields/TextField';
import QuestionLayout from './QuestionLayout';

import { updateLocalPreCustomer } from 'actions/core/app_actions';
import { interpolateQuestionText } from 'lib/checkout-experience/questionnaire/format';
import { RelevantProduct } from 'models/alloy/experience';
import { QuestionnaireAnswers } from 'models/components/questionnaire';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactMarkdown from 'react-markdown';

interface Props {
  question: AlloyQuestion;
  answers: QuestionnaireAnswers;
  followUps?: QuestionnaireAnswers;
  setFollowUps?: (followUps: QuestionnaireAnswers) => void;
  setAnswers: (answers: QuestionnaireAnswers) => void;
  relevantProducts?: RelevantProduct[];
  introduction?: JSX.Element;
}

/**
 * Question handles each individual question and how to render it. It has many different types but all fall back to the same idea
 * of saving off an answer to its parent component
 */
export const Question = ({
  question,
  answers,
  followUps,
  setAnswers,
  setFollowUps,
  relevantProducts,
  introduction,
}: Props) => {
  const dispatch = useDispatch();

  const dispatchUpdateLocalPreCustomer = bindActionCreators(updateLocalPreCustomer, dispatch);

  switch (question.type) {
    case 'MultipleChoice': {
      return (
        <QuestionLayout
          title={question.title}
          description={question.description}
          whyAskingDescription={question.whyAskingDescription}
          introduction={introduction}
        >
          <SelectFieldsGroup
            selectedIds={(answers[question.slug] as string[]) ?? []}
            onChange={(checkboxAnswer) => {
              setAnswers({ ...answers, [question.slug]: checkboxAnswer });
            }}
            followUps={followUps}
            setFollowUps={setFollowUps}
            options={question.options.map((option) => ({
              id: option.slug,
              text: option.answer,
              ...(option.followUp && {
                followUp: {
                  title: option.followUp.title,
                  slug: option.followUp.slug,
                  description: option.followUp.description,
                },
              }),
            }))}
          />
        </QuestionLayout>
      );
    }

    case 'SingleChoice': {
      const questionTitle = interpolateQuestionText(
        question.title,
        relevantProducts?.map((rp) => rp.name),
        'and'
      );

      return (
        <QuestionLayout
          title={questionTitle}
          description={question.description}
          whyAskingDescription={question.whyAskingDescription}
          introduction={introduction}
        >
          <SelectFieldsGroup
            selectedIds={[answers[question.slug]] as string[]}
            onChange={(checkboxAnswers) => {
              const singleChoiceAnswer: string =
                (checkboxAnswers[checkboxAnswers.length - 1] as string) ?? '';

              let updatedAnswers: QuestionnaireAnswers = {
                ...answers,
                [question.slug]: singleChoiceAnswer,
              };

              setAnswers(updatedAnswers);
            }}
            options={question.options
              .filter(
                (o) =>
                  !o.parentOptionSlug ||
                  (o.parentOptionSlug && Object.values(answers).flat().includes(o.parentOptionSlug))
              )
              .map((option) => ({
                id: option.slug,
                text: option.answer,
                ...(option.parentOptionSlug && {
                  parentOptionSlugId: option.parentOptionSlug,
                }),
              }))}
            isSingleSelect
          />
        </QuestionLayout>
      );
    }

    case 'MarkdownCheckbox': {
      const questionTitle = interpolateQuestionText(
        question.title,
        relevantProducts?.map((rp) => rp.name),
        'and'
      );
      return (
        <QuestionLayout wrapperClasses='content-markdown-wrapper' title={questionTitle}>
          <ReactMarkdown
            components={{
              a: ({ href, children, ...props }) => {
                const prodMarketingBase = 'https://www.myalloy.com';

                // Check if href exists
                if (href) {
                  const newHref = href.replace(
                    prodMarketingBase,
                    process.env.REACT_APP_MARKETING_URL || prodMarketingBase
                  );
                  return (
                    <a href={newHref} target='_blank' rel='noopener noreferrer' {...props}>
                      {children}
                    </a>
                  );
                }
                return <span {...props}>{children}</span>;
              },
            }}
          >
            {question.description}
          </ReactMarkdown>
          <SelectFieldsGroup
            selectedIds={(answers[question.slug] as string[]) ?? []}
            onChange={(checkboxAnswer) => {
              setAnswers({ ...answers, [question.slug]: checkboxAnswer });
            }}
            followUps={followUps}
            setFollowUps={setFollowUps}
            options={question.options.map((option) => ({
              id: option.slug,
              text: option.answer,
              ...(option.followUp && {
                followUp: {
                  title: option.followUp.title,
                  slug: option.followUp.slug,
                  description: option.followUp.description,
                },
              }),
            }))}
          />
        </QuestionLayout>
      );
    }

    case 'SingleLine': {
      return (
        <QuestionLayout
          title={question.title}
          description={question.description}
          whyAskingDescription={question.whyAskingDescription}
          introduction={introduction}
        >
          <TextField
            name={question.slug}
            value={answers[question.slug] as string}
            placeholder={question.placeholder}
            onChange={(textAnswer) =>
              setAnswers({ ...answers, [question.slug]: textAnswer.trim() })
            }
            data-private='lipsum'
            data-sentry-mask
          />
        </QuestionLayout>
      );
    }

    case 'MultipleLine': {
      return (
        <QuestionLayout
          title={question.title}
          description={question.description}
          whyAskingDescription={question.whyAskingDescription}
          introduction={introduction}
        >
          {question.slug === 'anything-else-share-doctor' && (
            <img src={doctors} alt='doctors' className='question-img' />
          )}

          <TextAreaField
            name={question.slug}
            value={answers[question.slug] as string}
            rows={4}
            placeholder={question.placeholder}
            onChange={(textAnswer) =>
              setAnswers({ ...answers, [question.slug]: textAnswer.trim() })
            }
            data-private='lipsum'
            data-sentry-mask
          />
        </QuestionLayout>
      );
    }

    case 'Date': {
      return (
        <QuestionLayout
          title={question.title}
          description={question.description}
          whyAskingDescription={question.whyAskingDescription}
          introduction={introduction}
        >
          <DateField
            name={question.slug}
            value={answers[question.slug] as string}
            placeholder={question.placeholder}
            onChange={(dateAnswer) => {
              if (question.userKey === 'dob' && !dateAnswer.includes('_')) {
                dispatchUpdateLocalPreCustomer({
                  dateOfBirth: dateAnswer,
                });
              }

              setAnswers({ ...answers, [question.slug]: dateAnswer });
            }}
          />
        </QuestionLayout>
      );
    }

    case 'Select': {
      return (
        <QuestionLayout
          title={question.title}
          description={question.description}
          whyAskingDescription={question.whyAskingDescription}
          introduction={introduction}
        >
          <SelectField
            name={question.slug}
            value={answers[question.slug] as string}
            placeholder={question.placeholder}
            data={question.options.map((option) => ({ value: option.slug, label: option.answer }))}
            onChange={(selectAnswer) => {
              if (selectAnswer) {
                setAnswers({ ...answers, [question.slug]: selectAnswer });
              }
            }}
          />
        </QuestionLayout>
      );
    }

    case 'Number': {
      return (
        <QuestionLayout
          title={question.title}
          description={question.description}
          whyAskingDescription={question.whyAskingDescription}
          introduction={introduction}
        >
          <NumberField
            name={question.slug}
            value={parseInt(answers[question.slug] as string)}
            placeholder={question.placeholder}
            onChange={(numberAnswer) => {
              if ((numberAnswer as number) <= 1000) {
                setAnswers({ ...answers, [question.slug]: numberAnswer.toString() });
              }
            }}
          />
        </QuestionLayout>
      );
    }

    case 'Information': {
      return <QuestionLayout title={question.title} description={question.description} />;
    }

    default:
      return <></>;
  }
};
