import {
  IntakeCategory,
  ProductAndFrequency,
  ProductFrequencyRenewal,
  SubscriptionWithRenewal,
  getAllSubscriptionsForCustomer,
  getRecentSubmission,
  runSuggestions,
} from 'client/dist/generated/alloy';
import ProductRegistry from 'client/dist/product/productRegistry';
import { ExperienceCategory } from 'common/dist/models/experience';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { RecurrenceType } from 'common/dist/products/productFrequency';
import _ from 'lodash';
import { NewUpsell } from 'models/alloy/new-upsell';
import { store } from 'store';
import { getBundledUpdatedCategory } from '../category';
import { getIntakeCategories } from '../experience';
import { getSubscriptionsWithStatus } from '../subscriptions/status';

export type ProductCleanNameDosage = {
  cleanName: string;
  dosage?: string;
};

export type ProductCleanNamePrice = {
  cleanName: string;
  priceInCents: number;
};

/**
 * We have a difference that makes the ProductAndFrequency from common have dates as a Date type
 * and the ProductAndFrequency from dash have dates as a string type. This function translates
 * them to be used on Dash without ts getting mad at them.
 *
 * @param products ProductAndFrequency
 * @returns ProductAndFrequency
 */
export const translateToDomProduct = (product: any): ProductAndFrequency => {
  return {
    ...product,
    createdAt: product.createdAt.toString(),
    updatedAt: product.updatedAt.toString(),
  };
};

/**
 * TODO tests
 *
 * Get new upsell products grouped by category to show
 * depending on purchased products and not purchased products.
 *
 * @param purchasedProductIds
 * @param notPurchasedProductIds
 * @param upsellProductIds
 * @returns an NewUpsell array, each one of them including the display name of the category,
 * the category union type string and the products for that category to show
 */
export const getNewUpsellContentfulProducts = async (
  purchasedProductIds: ProductAndFrequency['productId'][],
  notPurchasedProductIds: ProductAndFrequency['productId'][],
  upsellProductIds: ProductAndFrequency['productId'][]
): Promise<NewUpsell[]> => {
  const groupedAllUpsellProducts = await ProductRegistry.get().getNewUpsellContentfulProducts(
    purchasedProductIds,
    notPurchasedProductIds,
    upsellProductIds
  );

  const groupedByCategory = _.groupBy(groupedAllUpsellProducts, ({ alloyProduct }) =>
    _.uniq([
      ...alloyProduct.parent.map((p) => p.category),
      ...(alloyProduct.child && alloyProduct.child.length
        ? alloyProduct.child.map((c) => c.category)
        : []),
    ]).toString()
  );

  const groupedCategoryContentfulProduct = Object.keys(groupedByCategory).map((key) => ({
    category: key,
    products: groupedByCategory[key],
  }));

  // get bundled category, so sexual and vaginal health get in the same bundled category
  const groupedBundledCategory = getBundledUpdatedCategory(groupedCategoryContentfulProduct);

  return groupedBundledCategory;
};

/**
 * get the price for all products combined, this is mainly used to confirm that pill+prog are correctly combined in terms of price
 * and then returned.
 *
 * @param groupedProduct GroupedContentfulProduct
 * @returns number
 */
export const getPriceForCombinedProducts = (groupedProduct: GroupedContentfulProduct): number => {
  const { alloyProduct } = groupedProduct;
  const allProducts = [...alloyProduct.parent, ...(alloyProduct.child ?? [])];

  return allProducts.reduce((a, p) => a + p.priceInCents, 0);
};

/**
 * get the name for all products combined from alloy product. this allows us to get dosage and then
 * formatting it to the user.
 *
 * @param groupedProduct GroupedContentfulProduct
 * @returns string
 */
export const getProductNameWithDosage = (
  groupedProduct: GroupedContentfulProduct
): ProductCleanNameDosage[] => {
  const { alloyProduct } = groupedProduct;
  const allProducts = [...alloyProduct.parent, ...(alloyProduct.child ?? [])].flat();

  return allProducts.map((p) => ({
    cleanName: p.cleanName,
    ...(p.dosage && {
      dosage: p.dosage,
    }),
  }));
};

/**
 * get the name and price in cents for all products combined from alloy products passed.
 * this allows us to get the name of the product we want w/o the dosage and also what it costs in cents.
 *
 * @param groupedContentfulProducts GroupedContentfulProduct[]
 * @returns and object containing the `cleanName` and `priceInCents` for each product
 */
export const getProductNamesWithPrice = (
  groupedContentfulProducts: GroupedContentfulProduct[]
): ProductCleanNamePrice[] => {
  const allAlloyProducts = groupedContentfulProducts.map((gcp) => gcp.alloyProduct);

  const allProducts = allAlloyProducts.map((aap) => [...aap.parent, ...(aap.child ?? [])]).flat();

  return allProducts.map((p) => ({
    cleanName: p.cleanName,
    priceInCents: p.priceInCents,
  }));
};

/**
 * Helper function for checking if active subscriptions exist, and if so
 * Removing them from retrieval.
 * TODO: convert to cache use
 * @returns number[] containing product IDs for all active subscriptions
 */
export const retrieveActiveSubProductIds = async (): Promise<number[]> => {
  const { isAuthenticated } = store.getState().alloy;
  if (isAuthenticated) {
    const subscriptions = await getAllSubscriptionsForCustomer();
    const activeProducts = getSubscriptionsWithStatus(subscriptions).activeSubs;
    const activeSubProductIds = activeProducts.flatMap((sub) =>
      sub.products.map((p) => p.product.productId)
    );
    return activeSubProductIds;
  }
  return [];
};

/**
 *
 * @param productIds number[]
 * @param categories ExperienceCategory[]
 * @param submissionExists boolean
 * @returns GroupedContentfulProduct[]
 */
export const retrieveGroupedContentfulProductsFrom = async (
  productIds: number[],
  categories: ExperienceCategory[],
  submissionExists: boolean
): Promise<GroupedContentfulProduct[][]> => {
  const { isAuthenticated } = store.getState().alloy;

  const intakeCategories = getIntakeCategories(categories);

  // In order to handle some products being filtered against in the skin cart at least at the moment,
  // we need to find submission and run suggestions and then get those qualified products to build cart
  let qualifiedProductIds: number[] = [];
  let disqualifiedProductIds: number[] = [];
  let bundableProductIds: number[] = [];

  if (isAuthenticated && !intakeCategories.includes('mht')) {
    if (submissionExists) {
      const recentSubmission = await getRecentSubmission({ categories: intakeCategories });

      if (recentSubmission) {
        const suggestions = await runSuggestions({ submissionId: recentSubmission.id!! });

        qualifiedProductIds = suggestions.qualified.map((q) => q.product.productId);
        disqualifiedProductIds = suggestions.disqualified.map((dq) => dq.product.productId);
      }

      for (const productId of productIds) {
        if (await ProductRegistry.get().canBeBundled(productId)) {
          bundableProductIds.push(productId);
        }
      }
    }
  }

  // filter out the parent/child from original products and replace with our suggestions
  // ie m4 and tret lowest dosage filter out and put in m4 and tret highest dosage if qualified!
  const activeSubProductIds = await retrieveActiveSubProductIds();
  const productIdsToFetch = productIds
    .filter(
      (productId) =>
        !disqualifiedProductIds.includes(productId) && !bundableProductIds.includes(productId)
    )
    .concat(qualifiedProductIds)
    .filter((pid) => !activeSubProductIds.includes(pid));

  const fetchedProducts = await ProductRegistry.get().getRecurringProductsForV2(productIdsToFetch);
  return fetchedProducts;
};

export const getReadablePrice = (priceInCents: number): number => {
  return priceInCents / 100;
};

/**
 * Retrieves all of the pf ids from a grouped contentful product and returns them flattened
 *
 * @param gcp GroupedContentfulProduct
 * @returns number[]
 */
export const getProductFrequencyIdsFrom = (gcp: GroupedContentfulProduct): number[] => {
  return [
    ...gcp.alloyProduct.parent.map((pf) => pf.id),
    ...(gcp.alloyProduct.child ?? []).map((pf) => pf.id),
  ];
};

/**
 * Given a list of GroupedContentfulProducts, it returns a list of product ids in it
 *
 * @param products GroupedContentfulProduct[]
 * @returns ProductAndFrequency['productId'][]
 */
export const getProductIdsFromGroupedProducts = (
  products: GroupedContentfulProduct[]
): ProductAndFrequency['productId'][] => {
  return products.flatMap((gcp) => [
    ...gcp.alloyProduct.parent.map((pf) => pf.productId),
    ...(gcp.alloyProduct.child ?? []).map((pf) => pf.productId),
  ]);
};

/**
 * Given a specific product and a list of GroupedContentfulProducts,
 * it returns a product from the list where the specific product
 * can be bundled with
 *
 * @param bundledProduct GroupedContentfulProduct
 * @param products GroupedContentfulProduct[][]
 * @returns GroupedContentfulProduct
 */
export const getProductToBeBundledWith = (
  bundledProduct: GroupedContentfulProduct,
  products: GroupedContentfulProduct[][]
): GroupedContentfulProduct | undefined => {
  if (!bundledProduct.contentfulProduct.fields.bundledPrice) {
    return;
  }

  const bundledProducts =
    products.find((gcpList) =>
      gcpList.some(
        (gcp) => gcp.contentfulProduct.sys.id === bundledProduct.contentfulProduct.sys.id
      )
    ) || [];

  if (bundledProducts.length) {
    return bundledProducts.find(
      (gcp) =>
        gcp.contentfulProduct.sys.id !== bundledProduct.contentfulProduct.sys.id &&
        !gcp.contentfulProduct.fields.bundledPrice
    );
  }
};

/**
 * Given a list of GroupedContentfulProducts and a list of categories,
 * this function sorts every product in its own group according to the
 * order of categories in the categories list.
 *
 * @param products GroupedContentfulProduct[][]
 * @param categories IntakeCategory[]
 * @returns GroupedContentfulProduct
 */
export const sortGroupedProductsByCategories = (
  products: GroupedContentfulProduct[][],
  categories: IntakeCategory[]
): GroupedContentfulProduct[][] => {
  return products.map((gcpList) =>
    gcpList.sort((a, b) => {
      const aCategory = a.alloyProduct.parent[0].category;
      const bCategory = b.alloyProduct.parent[0].category;
      return (
        categories.findIndex((category) => category === aCategory) -
        categories.findIndex((category) => category === bCategory)
      );
    })
  );
};

/**
 * Given a product to be requested as addon, verify if any product from same bundle as it
 * is currently available to be requested to. (no subscriptions or prescriptions for all products from bundle)
 * If they are, then return all products from bundle to be added.
 * If not, only return current requesting product.
 *
 * @param product GroupedContentfulProduct
 * @param activeSubProducts GroupedContentfulProduct[][]
 * @param activeSubscriptions SubscriptionWithRenewal[]
 * @param nonSubscriptionProducts ProductFrequencyRenewal[]
 * @returns GroupedContentfulProduct[]
 */
export const getAddonRequestProducts = (
  product: GroupedContentfulProduct,
  activeSubProducts: GroupedContentfulProduct[][],
  activeSubscriptions: SubscriptionWithRenewal[],
  nonSubscriptionProducts: ProductFrequencyRenewal[]
) => {
  // moving this to handle either all products in a bundle if no product from bundle in any subs or prescriptions
  const allProductsFromBundle =
    activeSubProducts.find((gcpList) =>
      gcpList.some((gcp) => gcp.contentfulProduct.sys.id === product.contentfulProduct.sys.id)
    ) ?? [];
  const allProductIds = getProductIdsFromGroupedProducts(allProductsFromBundle);

  const noProductsOnSubsOrPrescriptions =
    activeSubscriptions.every((sub) =>
      sub.products.every((pfr) => !allProductIds.includes(pfr.product.productId))
    ) && nonSubscriptionProducts.every((pfr) => !allProductIds.includes(pfr.product.productId));

  const requestingProducts = noProductsOnSubsOrPrescriptions ? allProductsFromBundle : [product];

  return requestingProducts;
};

export const filterProductsByCategories = (
  products: GroupedContentfulProduct[][],
  categories: ExperienceCategory[]
) => {
  return products.map((gcpList) =>
    gcpList.filter((gcp) =>
      gcp.contentfulProduct.fields.categories.some((c) => categories.includes(c))
    )
  );
};

/**
 * Given a list of product frequencies, it returns a 2D array of GroupedContentfulProducts
 * correctly grouping them by their respective recurrence type
 *
 * @param productFrequencies ProductAndFrequency[]
 * @returns GroupedContentfulProduct[][]
 */
export const getProductsWithRecurrence = async (productFrequencies: ProductAndFrequency[]) => {
  const [recurringProductIds, oneTimeProductIds] = _.partition(
    productFrequencies,
    (p) => p.recurrenceType === 'RECURRING'
  );

  const [recurringProducts, oneTimeProducts] = await Promise.all([
    ProductRegistry.get().getRecurringProductsForV2(recurringProductIds.map((p) => p.productId)),
    ProductRegistry.get().getRecurringProductsForV2(
      oneTimeProductIds.map((p) => p.productId),
      'ONE_TIME' as RecurrenceType
    ),
  ]);

  return ProductRegistry.get().sortGroupedContentfulProducts([
    ...oneTimeProducts,
    ...recurringProducts,
  ]);
};
