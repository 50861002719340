import {
  ProductAndFrequency,
  ProductFrequencyRenewal,
  SubscriptionWithRenewal,
} from 'client/dist/generated/alloy';

/**
 *
 * Retrieves all the expired prescriptions in all shipments and returns as pf[]
 *
 * @param shipments SubscriptionWithRenewal[]
 * @returns ProductAndFrequency[] - all the expired prescriptions from all shipments
 */
export const getExpiringPrescriptionsFromAll = (
  shipments: SubscriptionWithRenewal[]
): ProductAndFrequency[] => {
  let products: ProductAndFrequency[] = [];

  shipments.forEach((shipment: SubscriptionWithRenewal) => {
    const expiredPerShipmentProducts = getExpiringPrescriptionsFromSubscription(shipment);

    products = [...products, ...expiredPerShipmentProducts];
  });

  return products;
};

/**
 *
 * Retrieves all the expired prescriptions in a shipment and returns as pf[]
 *
 * @param shipment SubscriptionWithRenewal
 * @returns ProductAndFrequency[] - all expired prescriptions from shipments
 */
export const getExpiringPrescriptionsFromSubscription = (
  shipment: SubscriptionWithRenewal
): ProductAndFrequency[] => {
  let products: ProductAndFrequency[] = [];

  shipment.products.forEach((p: ProductFrequencyRenewal) => {
    if (p.renewal && p.renewal.needsRenewed && !p.renewal.completedDate) {
      products.push(p.product);
    }
  });

  return products;
};
