import {
  ProductAndFrequency,
  Submission,
  SubmissionAnswer,
  useGetAllSubscriptionsForCustomer,
  useGetTreatmentPlan,
} from 'client/dist/generated/alloy';
import { uniq } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { completeRenewal } from 'actions/checkout-experience/renewal_actions';

import QuestionnaireRoutes from 'components/checkout-experience/questionnaire/QuestionnaireRoutes';
import Loader from 'components/core/Loader';

import { retrieveFlowFromUrl } from 'lib/checkout-experience/flow';
import { getExpiringPrescriptionsFromAll } from 'lib/dashboard/subscription';
import { retrieveCategoriesFromUrl } from 'lib/shared/experience';

import { getItem } from 'client/dist/localstorage';
import useMarkNotificationsAsRead from 'hooks/shared/useMarkNotificationsAsRead';
import { ExperienceComponentModel } from 'models/alloy/experience';
import { PreviousAnswersLocalStorage } from 'models/alloy/questionnaire';

export default function Renewal({ onNext, onBack }: ExperienceComponentModel) {
  // mark notifications as read once on everytime going to page OR reloading
  useMarkNotificationsAsRead(['RENEWAL_KICKOFF']);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const dateStarted = new Date();

  const [isLoading, setIsLoading] = useState(true);
  const [previousResults, setPreviousResults] = useState({});
  const [expiringProductFrequencies, setExpiringProductFrequencies] = useState<
    ProductAndFrequency[]
  >([]);

  const flow = retrieveFlowFromUrl(location);
  const categories = retrieveCategoriesFromUrl(location);
  const intakeQuestions = flow.intakeQuestions!!;

  const {
    data: subscriptions = [],
    isLoading: isLoadingSubscriptions,
    mutate: mutateSubscriptions,
  } = useGetAllSubscriptionsForCustomer();

  const { mutate: mutateTreatmentPlan } = useGetTreatmentPlan();

  const dispatchCompleteRenewal = bindActionCreators(completeRenewal, dispatch);

  useEffect(() => {
    if (isLoadingSubscriptions) return;

    const expiring = getExpiringPrescriptionsFromAll(subscriptions);

    if (expiring.length === 0) {
      history.push('/');
      return;
    }

    let recentIntakeAnswers: PreviousAnswersLocalStorage | undefined = getItem(
      'recentIntakeAnswers',
      true
    );

    if (
      recentIntakeAnswers &&
      recentIntakeAnswers.categories &&
      recentIntakeAnswers.categories.sort().toString() === categories.sort().toString()
    ) {
      setPreviousResults(recentIntakeAnswers.answers);
    }

    setExpiringProductFrequencies(expiring);
    setIsLoading(false);
  }, [subscriptions.length, isLoadingSubscriptions]);

  const onSubmit = async (answers: SubmissionAnswer[]) => {
    try {
      setIsLoading(true);

      const submission: Submission = {
        categories: ['renewal'],
        started: dateStarted.toISOString(),
        answers: answers,
      };

      await dispatchCompleteRenewal(submission, expiringProductFrequencies);

      await Promise.all([mutateTreatmentPlan, mutateSubscriptions]);

      onNext();
    } catch (error) {
      setIsLoading(false);
    }
  };

  if (isLoading || isLoadingSubscriptions) return <Loader />;

  return (
    <QuestionnaireRoutes
      questions={intakeQuestions}
      previousResults={previousResults}
      onSubmit={onSubmit}
      onBack={onBack}
      hideProgressBar
      parentUrlPath='/checkout-experience/renewal'
      metaTitle='Renewal | Alloy'
      relevantProducts={uniq(
        expiringProductFrequencies.map((pf) => {
          return { name: pf.name, category: pf.category };
        })
      )}
    />
  );
}
