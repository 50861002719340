import {
  useGetAllOrders,
  useGetAllSubscriptionsForCustomer,
  useGetTreatmentPlan,
} from 'client/dist/generated/alloy';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useAppSelector } from 'reducers/alloy_reducer';
import { bindActionCreators } from 'redux';

import { clearTPCart, updateTPCart } from 'actions/treatment-plan/cart_actions';

import Loader from 'components/core/Loader';
import ActivePlanContainer from 'components/dashboard/treatment-plan/containers/ActivePlan';
import AwaitingQueueOrCX from 'components/dashboard/treatment-plan/containers/AwaitingQueueOrCX';
import ConfirmationContainer from 'components/dashboard/treatment-plan/containers/Confirmation';
import DoctorReviewContainer from 'components/dashboard/treatment-plan/containers/DoctorReview';
import EmptyPlanContainer from 'components/dashboard/treatment-plan/containers/EmptyPlan';
import PendingApprovalContainer from 'components/dashboard/treatment-plan/containers/PendingApproval';

import DashboardContainer from 'containers/dashboard/DashboardContainer';

import useMarkNotificationsAsRead from 'hooks/shared/useMarkNotificationsAsRead';

import { getAccountStatus } from 'lib/core/customer/getAccountStatus';

export default function TreatmentPlan() {
  // mark notifications as read once on everytime going to page OR reloading
  useMarkNotificationsAsRead([
    'NEW_PRESCRIPTION',
    'TREATMENT_PLAN_READY',
    'TREATMENT_PLAN_UPDATED',
  ]);

  const dispatch = useDispatch();
  const history = useHistory();

  const customer = useAppSelector((state) => state.alloy.customer!);
  const { treatmentPlanCart: cart } = useAppSelector((state) => state.treatmentPlan);

  const [loading, setLoading] = useState<boolean>(false);
  const [isOrderPlaced, setIsOrderPlaced] = useState<boolean>(cart.isPurchased);

  const {
    data: treatmentPlan,
    isLoading: isLoadingTreatmentPlan,
    mutate: mutateTreatmentPlan,
  } = useGetTreatmentPlan();
  const {
    data: subscriptions = [],
    isLoading: isLoadingSubscriptions,
    mutate: mutateSubscriptions,
  } = useGetAllSubscriptionsForCustomer();
  const { data: orders = [], isLoading: isLoadingOrders } = useGetAllOrders();

  // See if anything is loading
  const isLoading = loading || isLoadingTreatmentPlan || isLoadingSubscriptions || isLoadingOrders;

  // Update the treatment plan cart or clear it
  const dispatchUpdateCart = bindActionCreators(updateTPCart, dispatch);
  const dispatchClearCart = bindActionCreators(clearTPCart, dispatch);

  const onContinueCheckout = () => history.push('/treatment-plan/checkout');

  /**
   * View the treatment plan after purchase
   */
  const onViewTreatmentPlan = async () => {
    window.scrollTo(0, 0);

    setLoading(true);

    await Promise.all([mutateTreatmentPlan(), mutateSubscriptions()]);

    dispatchClearCart();

    setIsOrderPlaced(false);
    setLoading(false);
  };

  /**
   * Stuff is loading
   */
  if (isLoading) {
    return (
      <DashboardContainer title='Treatment Plan | Alloy' desc='' currentPage='treatment plan'>
        <Loader />
      </DashboardContainer>
    );
  }

  if (isOrderPlaced && cart.products.length !== 0) {
    return <ConfirmationContainer onViewTreatmentPlan={onViewTreatmentPlan} />;
  }

  const accountStatus = getAccountStatus(customer.status, subscriptions, orders, treatmentPlan);

  switch (accountStatus) {
    case 'NO_PURCHASE':
      return <EmptyPlanContainer />;

    case 'AWAITING_CX_OR_QUEUE':
      return <AwaitingQueueOrCX />;

    case 'AWAITING_DOCTOR':
      return <DoctorReviewContainer />;

    case 'AWAITING_CUSTOMER':
      return <PendingApprovalContainer onContinueCheckout={onContinueCheckout} />;

    default:
      return <ActivePlanContainer />;
  }
}
