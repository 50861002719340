import posthog from 'posthog-js';

export const shouldRemoveReviewStep = () => {
  const removeReviewStatus = posthog.getFeatureFlag('remove-review');
  console.log('Retrieved result of remove-review is: ', removeReviewStatus);

  return removeReviewStatus === 'test';
};

export const showProductReliefTypeSelections = () => {
  const reliefTypeTestToShow = posthog.getFeatureFlag('relief-type');

  console.log('Retrieved result of relief-type is: ', reliefTypeTestToShow);

  return typeof reliefTypeTestToShow === 'string'
    ? reliefTypeTestToShow.indexOf('product') > -1
    : reliefTypeTestToShow;
};

export const showCategoryReliefTypeSelections = () => {
  const reliefTypeTestToShow = posthog.getFeatureFlag('relief-type');

  return typeof reliefTypeTestToShow === 'string'
    ? reliefTypeTestToShow.indexOf('category') > -1
    : reliefTypeTestToShow;
};
