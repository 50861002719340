import { updateToNewCheckoutExperienceFlow } from 'actions/checkout-experience/flow_actions';
import {
  startRenewalAlert,
  SubscriptionWithRenewal,
  useGetAllSubscriptionsForCustomer,
  useIsProcessing,
} from 'client/dist/generated/alloy';
import { getPrescriptionsStatusFrom } from 'lib/dashboard/prescription';
import { getExpiringPrescriptionsFromSubscription } from 'lib/dashboard/subscription';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { bindActionCreators } from 'redux';

interface Props {
  subscription: SubscriptionWithRenewal;
  isLoading: boolean;
}

// we know a subscription will always exist at this level
export const SubscriptionContext = createContext<Props>({
  subscription: {} as SubscriptionWithRenewal,
  isLoading: true,
});

export const useSubscriptionContext = () => {
  const subscription = useContext(SubscriptionContext);

  return subscription;
};

export default function SubscriptionContextProvider({ children }: { children: ReactNode }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{ id?: string }>();

  const [isLoading, setIsLoading] = useState(true);
  const [subscription, setSubscription] = useState<SubscriptionWithRenewal>(
    {} as SubscriptionWithRenewal
  );

  const { data: subscriptions = [], isLoading: isLoadingSubscriptions } =
    useGetAllSubscriptionsForCustomer();
  const {
    data: isProcessing,
    isLoading: isLoadingProcessing,
    error,
  } = useIsProcessing(params.id!!);

  const dispatchUpdateToNewCheckoutExperience = bindActionCreators(
    updateToNewCheckoutExperienceFlow,
    dispatch
  );

  useEffect(() => {
    refreshSubscriptionSearch();
  }, [JSON.stringify(subscriptions), isLoadingSubscriptions]);

  useEffect(() => {
    if (isProcessing || error) {
      history.replace('/subscriptions');
    }
  }, [isProcessing, error]);

  /**
   * find subscription from url in our subscriptions list
   * if a subscription is in a state of needing renewal, we throw customer in renewal
   * otherwise we let them see subscription
   */
  const refreshSubscriptionSearch = () => {
    setIsLoading(true);

    let subscriptionId = params.id;

    // exit out early if no id or loading
    if (!subscriptionId || isLoadingSubscriptions) return;

    const subscriptionFound = subscriptions.find(
      (item) => item.stripeSubscriptionId === subscriptionId
    );

    // no sub or not active we don't want to display the subscription for customer
    if (!subscriptionFound || subscriptionFound.status !== 'ACTIVE') {
      history.replace('/subscriptions');

      return;
    }

    const { allPrescriptionsActive, prescriptionsNeedRenewal } =
      getPrescriptionsStatusFrom(subscriptionFound);

    // when sub needs to be renewed, we need to throw customer in renewal flow
    if (!allPrescriptionsActive || prescriptionsNeedRenewal) {
      const productFrequencies = getExpiringPrescriptionsFromSubscription(subscriptionFound);

      const productNames = productFrequencies.map((pf) => pf.name);

      startRenewalAlert({ productNames });

      dispatchUpdateToNewCheckoutExperience(['renewal'], history);
    } else {
      setSubscription(subscriptionFound);
      setIsLoading(false);
    }
  };

  return (
    <SubscriptionContext.Provider
      value={{
        subscription,
        isLoading: isLoading || isLoadingSubscriptions || isLoadingProcessing,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
}
